<script>
import ApiService from "@services/api.service";

export default {
  name: "ActivityResult",
  data() {
    return {
      table: {
        sortBy: "behaviour",
        fields: [
          { key: "description", sortable: true, label: "Activity Result" },
          { key: "status", sortable: true },
          { key: "active", class: "text-right" },
          { key: "actions", class: "text-right" }
        ],
        data: [],
        filter: null
      },
      parameter: {
        statusList: [
          { code: 0, description: "Open" },
          { code: 1, description: "Pending" },
          { code: 2, description: "Closed" }
        ]
      },
      activityType: {
        description: null,
        behaviour: null
      },
      showModal: false
    };
  },
  computed: {
    rowCount: function () {
      return this.table.data.length;
    },
    editMode: function () {
      return this.activityType.code != null;
    }
  },
  created() {
    this.loadTable();
  },
  methods: {
    loadTable() {
      this.changeLoader(true);
      ApiService.get("ActivityResult")
        .then(resp => {
          this.table.data = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    openDetail(selectedRow) {
      if (selectedRow) this.activityType = { ...selectedRow };
      this.showModal = true;
    },
    behaviourConverter(value) {
      var x = this.parameter.statusList.find(x => x.code == value);
      return x.description;
    },
    save() {
      if (!this.editMode) {
        this.changeLoader(true);
        ApiService.post("ActivityResult", this.activityType)
          .then(() => {
            this.showToast("success", "Create Successful");
            this.loadTable();
            this.showModal = false;
          })

          .finally(() => {
            this.changeLoader(false);
          });
      } else {
        this.changeLoader(true);
        ApiService.put("ActivityResult", this.activityType)
          .then(() => {
            this.showToast("success", "Edit Successful");
            this.loadTable();
            this.showModal = false;
          })

          .finally(() => {
            this.changeLoader(false);
          });
      }
    },
    toggle(code) {
      this.changeLoader(true);
      let qs = {
        code: code
      };
      ApiService.patch("ActivityResult", null, qs)
        .then(resp => {
          this.showToast("success", "Toggle Successful");
          this.loadTable();
        })

        .finally(() => {
          this.changeLoader(false);
        });
    },
    clearForm() {
      this.activityType = {
        description: null,
        behaviour: null
      };
    }
  }
};
</script>

<template>
  <div>
    <b-card>
      <div class="d-flex mb-2">
        <b-btn variant="outline-primary" @click.stop="openDetail(null)">Create</b-btn>
        <div class="ml-auto">
          <b-input v-model="table.filter" placeholder="Filter" class="form-control text-right" />
        </div>
      </div>

      <b-table
        :items="table.data"
        :fields="table.fields"
        :sort-by.sync="table.sortBy"
        :filter="table.filter"
        no-provider-sorting
        no-provider-filtering
        empty-text="No data to show"
        striped
        outlined
        hover
        show-empty
        fixed
      >
        <template #cell(status)="data">
          <div>
            {{ behaviourConverter(data.item.behaviour) }}
          </div>
        </template>
        <template #cell(active)="data">
          <div>
            <b-badge :variant="data.item.isActive ? 'success' : 'warning'" class="text-capitalize">
              {{ data.item.isActive ? "Active" : "Inactive" }}
            </b-badge>
          </div>
        </template>
        <template #cell(actions)="data">
          <div>
            <b-btn class="btn btn-sm btn-wide mr-1" :class="data.item.isActive ? 'btn-secondary' : 'btn-info'" @click.stop="toggle(data.item.code)">{{
              data.item.isActive ? "Deactivate" : "Activate"
            }}</b-btn>
            <b-btn class="btn btn-sm btn-wide btn-warning mr-1" @click.stop="openDetail(data.item)">Edit</b-btn>
            <!-- <b-btn class="btn btn-sm btn-wide btn-danger" @click.stop="openDetail(data.item)">Delete</b-btn> -->
          </div>
        </template>
      </b-table>
    </b-card>

    <b-modal v-model="showModal" size="lg" hide-footer centered :backdrop="true" @hidden="clearForm">
      <div class="px-3 py-2">
        <b-row>
          <b-col class="text-center">
            <h2>{{ !editMode ? `Create New Activity Result` : `Edit Activity Result` }}</h2>
          </b-col>
        </b-row>
        <hr />
        <b-form @submit.stop.prevent="save">
          <b-row class="mb-2">
            <b-col md="4">
              <label>Activity Result</label>
            </b-col>
            <b-col md="8">
              <b-input v-model="activityType.description" class="form-control" />
              <small>Activity Result</small>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col md="4">
              <label>Status</label>
            </b-col>
            <b-col md="8">
              <b-form-select
                v-model="activityType.behaviour"
                :options="parameter.statusList"
                label="description"
                value-field="code"
                text-field="description"
              />
              <small>Activity Result Status</small>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col>
              <b-btn block variant="danger" @click.stop="showModal = false">Cancel</b-btn>
            </b-col>
            <b-col>
              <b-btn block type="submit" variant="success">Save</b-btn>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>
